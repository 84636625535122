import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { startOfToday } from 'date-fns'
import BookingForm from 'components/BookingForm'
import Recaptcha from 'components/Recaptcha'
import { book } from 'actions/booking'
import { validateRecaptcha } from 'actions/recaptcha'
import { useSnackbar } from 'notistack'
import { useAuth } from 'hooks/usePublicAuth'
import { getAppCheckToken } from 'actions/firebase'

const Booking = ({ timetable, onDiscard, afterBooked, slot, fee, editable, location: { search } }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigatePaymentPage = (booking) => navigate(`/booking/${booking.id}/pay${search ?? ''}`)
  const { enqueueSnackbar } = useSnackbar()
  const { customer, user } = useAuth()

  const onSubmit = async (data) => {
    const token = await executeRecaptcha('book')

    const idToken = await user.getIdToken()
    // const appCheckToken = await getAppCheckToken()
    await validateRecaptcha(token, idToken)
    await user.getIdToken(true)
    const booking = await book(timetable.id, user.uid, data, slot, fee, customer?.toJSON())
    await user.getIdToken(true)

    enqueueSnackbar('Please wait for booking fee processing!', { variant: 'success' })

    setTimeout(async () => {
      if (afterBooked) {
        return await afterBooked(booking, () => {
          return navigatePaymentPage(booking)
        })
      }
      return navigatePaymentPage(booking)
    }, 3000)
  }

  onDiscard =
    onDiscard ??
    (() => {
      navigate('/')
    })

  return (
    <Recaptcha>
      <BookingForm
        timetable={timetable}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        cardStyle={{ boxShadow: 'none' }}
        minDate={startOfToday()}
        customer={customer?.toFormDefaultJSON()}
        editable={editable}
        loadingAfterSubmitted={true}
      />
    </Recaptcha>
  )
}

export default memo(Booking)
